@font-face {
    font-family: "Replica Pro Bold";
    src: url("../assets/fonts/ReplicaPro-Bold.eot");
    src: url("../assets/fonts/ReplicaPro-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/ReplicaPro-Bold.woff2") format("woff2"),
        url("../assets/fonts/ReplicaPro-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Replica Pro Heavy";
    src: url("../assets/fonts/ReplicaPro-Heavy.eot");
    src: url("../assets/fonts/ReplicaPro-Heavy.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/ReplicaPro-Heavy.woff2") format("woff2"),
        url("../assets/fonts/ReplicaPro-Heavy.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Replica Pro";
    src: url("../assets/fonts/ReplicaPro.eot");
    src: url("../assets/fonts/ReplicaPro.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/ReplicaPro.woff2") format("woff2"),
        url("../assets/fonts/ReplicaPro.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Replica Pro";
    src: url("../assets/fonts/ReplicaPro-Bold.eot");
    src: url("../assets/fonts/ReplicaPro-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/ReplicaPro-Bold.woff2") format("woff2"),
        url("../assets/fonts/ReplicaPro-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Replica Pro";
    src: url("../assets/fonts/ReplicaPro-Heavy.eot");
    src: url("../assets/fonts/ReplicaPro-Heavy.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/ReplicaPro-Heavy.woff2") format("woff2"),
        url("../assets/fonts/ReplicaPro-Heavy.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Replica Pro Light";
    src: url("../assets/fonts/ReplicaPro-Light.eot");
    src: url("../assets/fonts/ReplicaPro-Light.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/ReplicaPro-Light.woff2") format("woff2"),
        url("../assets/fonts/ReplicaPro-Light.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}

html {
    font-family: "Replica Pro", Helvetica, Arial, sans-serif;
    min-height: 100%;
}

html,
body {
    overflow-x: hidden;
    font-size: 62.5%;
}
.root {
    min-height: "100vh";
    flex-direction: column;
}
